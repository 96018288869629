
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Fira+Sans:wght@400;500&family=Inconsolata:wght@400;500&family=Jost:wght@400;500&family=Lato:wght@300;400;700&family=Lora&family=Open+Sans:wght@400;500;600;700&family=Roboto+Mono:ital,wght@0,500;1,400&family=Roboto:wght@400;500&display=swap');

body{
  font-family: 'Lato', sans-serif;
}

.quote {
  font-weight: 600;
  font-weight: bold;
  font-family: 'Roboto Mono', sans-serif;
}

.app-width {
 max-width: 900px;
}

.app-grid {
  margin-top: 50px;
  justify-items: center;
}